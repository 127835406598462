import readXlsxFile from 'read-excel-file';
import { formatInTimeZone } from 'date-fns-tz';

interface Flight {
    [key: string]: string
}

export async function convertExcelToJson(buffer: File): Promise<any> {
    const rows = await readXlsxFile(buffer) as Array<Array<any>>;
    const header = rows.shift() as Array<string>;

    const flights: Flight[] = rows.map((row) => {
        const fields: Flight = {};

        row.forEach((field, index) => {
            fields[header[index]] = field;
        })

        return fields;
    })

    const fieldFormatter = (
        maxLength: number,
        data: string | number,
        justifiedLeft: boolean,
    ) => {
        const value = data.toString();
        if (justifiedLeft) return value.padEnd(maxLength, ' ');

        return value.padStart(maxLength, ' ');
    };

    const dayOfOperationFormatter = (
        value: number | string,
    ) => {
        const dayOfOperation = value.toString();
        const dayArray = [1, 2, 3, 4, 5, 6, 7];

        return dayArray.map((day: typeof dayArray[number]) => {
            if (!dayOfOperation.includes(day.toString())) {
                return ' '
            }

            return day
        }).join('')
    };

    const dateFormater = (date: string) => {

        return date.toUpperCase().replaceAll('-', '')
    }
    const mappedFlights = flights.map((flight) => {

        return {
            iataAirlineCode: fieldFormatter(3, flight['IATA AIRLINE CODE'], true),
            flightNumber: fieldFormatter(5, flight['Flight No'], false),
            operationalSuffix: flight['OS'] || ' ',
            departureIata: fieldFormatter(5, flight['DEP IATA'], true),
            destinationIata: fieldFormatter(5, flight['DEST IATA'], true),
            operationalDays: dayOfOperationFormatter(flight['Day of operation']),
            effectiveFrom: dateFormater(flight['Eff From']),
            effectiveTill: dateFormater(flight['Eff Till']),
            atcCallSign: fieldFormatter(9, flight['ATC C/S'], true),
        };
    });

    const numberOfRecords = flights.length;
    const timestamp = formatInTimeZone(new Date(), 'UTC', 'ddMMMYHHmmss').toUpperCase();

    const callSignMapper = (mappedFlights: unknown[]) => {
        return mappedFlights.map((flight: any) => {
            return Object.values(flight).join('').padEnd(60, ' ')
        })
    }

    const signheader = ('CSR' + timestamp + numberOfRecords.toString().padStart(3, ' ')).padEnd(30, ' ')
    return (signheader + callSignMapper(mappedFlights).join(''))
}
