import React, { useState } from "react";
import "./App.css";
import { FileUploader } from "react-drag-drop-files";
import { convertExcelToJson } from "./utils";
import { saveAs } from "file-saver";
import { formatInTimeZone } from "date-fns-tz";

const fileTypes = ["xlsx", "xls", "csv"];

export default function App() {
  const [file, setFile] = useState<string>();
  const handleChange = async (file: File) => {
    const textContent = await convertExcelToJson(file);
    setFile(textContent);
  };

  const downloadFile = () => {
    if (file) {
      const timestamp = formatInTimeZone(
        new Date(),
        "UTC",
        "ddMMMYHHmmss"
      ).toUpperCase();

      const txtFile = new File([file], "lidoCallSigns.txt", {
        type: "text/plain;charset=utf-8",
      });
      saveAs(txtFile, `lidoCallSigns-${timestamp}.txt`);
    }
  };

  return (
    <div className="App">
      <header></header>
      <main>
        <h1>Euro control call signs converter</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            multiple={false}
          />
          <div
            style={{
              marginTop: 20
            }}
          >
            <button onClick={() => downloadFile()}>Download text file</button>
          </div>
        </div>
      </main>
    </div>
  );
}
